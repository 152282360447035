// JavaScript Document
/**
 * call simpods API, JQuery required
 * @var string parent.page_url defined by the hosting page, pass the parent's url
 * @var string parent.nonce defined by the hosting page for security check 
 */
var href_arr  	= window.location.href.split('/');
var url_arr  	= window.location.href.split('?');
// get the asset property asset=ID
var media_arr = href_arr[ href_arr.length - 2 ].split('_');
var query_str = href_arr[ href_arr.length - 1 ] + '&media=' + media_arr[ 1 ] ;
	
//var url_str   = parent.page_url + '/' + query_str + '&nonce=' + parent.nonce ;	

//$.get( url_str );
var asset_arr	= [];
var assets_obj	= {};
var querys_arr 	= url_arr[1].split('&');
if( querys_arr.length > 1 ){
	for (var i = 0; i < querys_arr.length ; i ++ ) {
		var query_arr 	= querys_arr[ i ].split('='); 
		assets_obj[ query_arr[0] ] = query_arr[1];
		if( query_arr[0] == 'asset' ){	//make it compatible with the old code
			asset_arr	=	[ query_arr[0], query_arr[1] ];
		}
	} 
} else {
	asset_arr = href_arr[ href_arr.length - 1 ].split('='); 
}
/**
 * to upload answers to the database
 * @param string|object|number answer_ukn the answer to submit
 * @param array append_answer if the answer is an object and there is an array in the object, e.g. {a:1,b:[]} and you don't want to overwrite b but add more to it, put ['b'] down.
 * @param object attrs more parameters to instruction the function e.g.{end_of_game: 1} to update the lead board
 */
function simpods_api( answer_ukn, append_answer = [], attrs = {} ){

	if( typeof parent.simpods_cpd_pass_asset == 'function' ){
		if( typeof answer_ukn === 'string' && jQuery.trim( answer_ukn ) == '' ){ // remove the registration of the asset from the answer field
			parent.simpods_cpd_pass_asset( asset_arr [ 1 ], '', parent.simpods_cpd_api_callback, append_answer, attrs );
		} else if ( typeof answer_ukn === 'object' || typeof answer_ukn === 'number' ) {
			parent.simpods_cpd_pass_asset( asset_arr [ 1 ], answer_ukn, parent.simpods_cpd_api_callback, append_answer, attrs );
			 
		} else if( typeof answer_ukn === 'string' )  {
			var answer_obj = JSON.parse( answer_ukn );
 
			if( typeof answer_obj === 'object' ){
				parent.simpods_cpd_pass_asset( asset_arr [ 1 ], answer_obj, parent.simpods_cpd_api_callback, append_answer, attrs );
			} else {
				parent.simpods_cpd_pass_asset( asset_arr [ 1 ], answer_ukn, parent.simpods_cpd_api_callback, append_answer, attrs );
			}

		} else {			
			parent.simpods_cpd_pass_asset( asset_arr [ 1 ], 1, parent.simpods_cpd_api_callback, append_answer, attrs );
		}
	}
}

function simpods_api_load(){	
	return parent.game_answers[ 'asset_' + asset_arr[1] ];
}

function simpods_api_answer_of_asset(){
	return parent.simpods_cpd_get_user_answer( asset_arr [ 1 ] ); 
}
var media_arr 		= href_arr[ href_arr.length - 2 ].split('_'); 
var iframeWid_int 	= window.parent.jQuery('iframe.game-' + media_arr[1] ).width();
var icscale_num		= 1;

jQuery(document).ready( function($) {
	var canvasWid_int	= jQuery('#canvas').width();
	
	icscale_num		= Math.round( iframeWid_int / canvasWid_int * 100 ) / 100;	
})